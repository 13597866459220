<script setup lang="ts">

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

defineProps<{
    alt: string
}>()

const show = defineModel<boolean>()

</script>

<template>
    <div class="map-overlay position-relative flex-center h-100">
        <img
            src="/images/hostel-map.jpg"
            :alt="alt"
            :title="alt"
            class="card-img h-100"
        >
        <button
            type="button"
            class="card-text btn bg-white tt-n btn-load-map position-absolute"
            :title="alt"
            @click="show = true"
        >
            <FontAwesomeIcon :icon="faMapMarkerAlt" />
            load map
        </button>
    </div>
</template>

<style scoped lang="scss">
.card-img {
    object-fit: cover;
}
</style>